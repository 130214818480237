.App {
  text-align: center;
}

.MuiDrawer-paper {
  background-color: #303030 !important;
}
.drawertoc .MuiDrawer-paper {
  min-width: 30% !important;
  max-width: 75% !important;
}
.drawersettings .MuiDrawer-paper, .drawerinfos .MuiDrawer-paper {
  width: 75% !important;
}

