blockquote div {
    border-left: 0.1em solid;
    padding: 0em 0.5em;
    font-style: italic;
    opacity: 0.5;
}
h2 div {
    text-align: center !important;
    text-transform: uppercase;
}
h3 div {
    text-align: center !important;
    text-transform: capitalize;
}
h4 div {
    text-align: center !important;
    text-transform: capitalize;
    font-style: italic;
    opacity: 0.5;
    font-weight: initial !important;
}
h2,h3,h4 {
    scroll-margin: 70px;
}

.DraftEditor-root{
    z-index: 1100; 
    margin: 2em 0;
}

.public-DraftStyleDefault-ltr {
    text-align: justify; 
}
.DraftEditor-root blockquote {
    margin: 0;
    margin-left: 1rem;
}
.DraftEditor-root div>div {
    margin-top: 1rem;
}

.DraftEditor-root div>span {
    font-weight: initial !important;
}

